import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { LABELS_LANDING_FOOTER } from "./constants/languages"

import logo from "../images/logo_nav.png"
import "./footer.css"

export const Footer = ({ lang }) => {
  return (
    <Container
      fluid
      className="footer text-light py-0 mt-5 pt-4 pb-3 text-left animated fadeInPlace"
    >
      <Container className="">
        <Row className="justify-content-between">
          <Col
            xs={12}
            md={3}
            className=" d-flex justify-content-center align-items-start"
          >
            <img
              src={logo}
              className="img-fluid
            "
              alt=""
            />
          </Col>
          <Col xs={12} md={9}>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <a className="link_footer" href="mailto:hola@geofisicagc.com">
                    info@geofisicacg.com
                  </a>
                  <p>
                    {" "}
                    <a className="link_footer" href="tel:+5212225887494">
                      {" "}
                      +521 2225887494{" "}
                    </a>
                  </p>

                  <hr className="w-25 hr_light ml-0" />
                  <address>
                  C. 14 Pte. 111-Local 11, San Juan Aquiahuac, San Andrés Cholula, Pue, Puebla, Mexico
                  </address>
                </Col>

                <Col xs={12} md={4}>
                  <p className="">{LABELS_LANDING_FOOTER.SOCIAL_NET[lang]}</p>
                  <div>
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/GeofisicaCG/"
                    >
                      <FontAwesomeIcon size="lg" icon={["fab", "facebook-f"]} />
                    </NavLink>{" "}
                    &nbsp;
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/GeofisicaCG"
                    >
                      <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} />
                    </NavLink>
                    &nbsp;
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/gcg-geof%C3%ADsica-138640139/"
                    >
                      <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} />
                    </NavLink>
                    &nbsp;
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UC-mxAEPqv3nEvVW9jnYYF5g"
                    >
                      <FontAwesomeIcon size="lg" icon={["fab", "youtube"]} />
                    </NavLink>
                    &nbsp;
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/5212225887494"
                    >
                      <FontAwesomeIcon size="lg" icon={["fab", "whatsapp"]} />
                    </NavLink>
                    &nbsp;
                    <NavLink
                      className="link_footer mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:info@geofisicacg.com"
                    >
                      <FontAwesomeIcon size="lg" icon={["far", "envelope"]} />
                    </NavLink>
                    &nbsp;
                    {/* <NavLink className="link_footer mr-1" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer" href="mailto:hola@designweekpuebla.com?subject=%C2%A1Quiero%2520informaci%25C3%25B3n!">
                        <FontAwesomeIcon size="lg" icon="map-signs"/>
                    </NavLink>
                    &nbsp; */}
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={4}
                  className="d-flex align-items-start flex-column"
                >
                  <NavLink tag={Link} className="link_footer" to="/nosotros/">
                    {LABELS_LANDING_FOOTER.US[lang]}
                  </NavLink>

                  <NavLink tag={Link} className="link_footer" to="/#serv">
                    {LABELS_LANDING_FOOTER.SERVICES[lang]}
                  </NavLink>

                  <NavLink tag={Link} className="link_footer" to="/#metodos">
                    {LABELS_LANDING_FOOTER.METHODS[lang]}
                  </NavLink>

                  <NavLink
                    tag={Link}
                    className="link_footer"
                    to="/nosotros/#alianzas"
                  >
                    {LABELS_LANDING_FOOTER.ALIANCES[lang]}
                  </NavLink>

                  <NavLink tag={Link} className="link_footer" to="/galeria">
                    {LABELS_LANDING_FOOTER.GALLERY[lang]}
                  </NavLink>

                  <NavLink tag={Link} className="link_footer" to="/#contacto/">
                    {LABELS_LANDING_FOOTER.CONTACT[lang]}
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="text-center mt-1">
          <Col>
            <p>@{`${new Date().getFullYear()}`} Geofísica CG</p>
            <br />
            <NavLink
              className="d-print-none link_footer"
              href="https://www.n12.mx"
            >
              Hecho a &nbsp;
              <span role="img" aria-label="palms together facing up icon">
                🤲🏽
              </span>
              artesanalmente &nbsp;por N12 Estudio
            </NavLink>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
