import React from "react"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LABELS_GLOBAL } from "../constants/languages"
const Modale = ({ modal, toggle, className, title, text, img, link, lang }) => {
  return (
    <div>
      <Modal
        isOpen={modal}
        modalTransition={{ timeout: 600 }}
        backdropTransition={{ timeout: 1000 }}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle} className="">
          {title}
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col md={4}>
              <img className="modimg" src={img} alt="" />
            </Col>
            <Col md={8} className="modaltext">
              <p className="multiline">{text}</p>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-flex-end">
          <p className="textomod">{LABELS_GLOBAL.INFO[lang]}</p>{" "}
          <br className="d-inline d-md-none" />
          <div className="ml-2 d-flex justify-content-between align-items-center">
            <a className="mr-2" href="mailto:explora@geofisicacg.com">
              <FontAwesomeIcon
                size="1x"
                className="iconsize"
                icon={["far", "envelope"]}
              />
            </a>

            <a
              className="mr-2"
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              download
            >
              <FontAwesomeIcon
                size="1x"
                className="iconsize"
                icon={["fas", "download"]}
              />
            </a>
            <a className="mr-2" href="https://wa.me/5212225887494">
              <FontAwesomeIcon
                size="1x"
                className="iconsize"
                icon={["fab", "whatsapp"]}
              />
            </a>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Modale
