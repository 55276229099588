import React from "react"

export const LABELS_PAGES_TITLES = {
  CLIENTS: {
    es: "Clientes",
    en: "Clients",
  },
  US: {
    es: "Nosotros",
    en: "Us",
  },
}

export const LABELS_NAVB = {
  US: {
    es: "Nosotros",
    en: "Us",
  },
  SERVICES: {
    es: "Servicios",
    en: "Services",
  },
  METHODS: {
    es: "Métodos",
    en: "Methods",
  },
  CLIENTS: {
    es: "Clientes",
    en: "Clients",
  },
  CONTACT: {
    es: "Contacto",
    en: "Contact",
  },
  GALLERY: {
    es: "Galería",
    en: "Gallery",
  },
}

export const LABELS_LANDING_JUMBO = {
  EXPLORE: {
    es: "Explora tus oportunidades",
    en: "Explore your opportunities",
  },
}

export const LABELS_GLOBAL = {
  GCG: {
    es: "GCG Geofísica",
    en: "GCG Geophysics",
  },
  INFO: {
    es: "Para mayor información:",
    en: "For more information",
  },
}

export const LABELS_LANDING_INTRO = {
  INTRO: {
    es: (
      <>
        es una empresa que cuenta con más de{" "}
        <b className="contrast"> 19 años de experiencia</b>
        en la{" "}
        <b className="contrast">
          {" "}
          caracterización del subsuelo mediante métodos geofísicos
        </b>
        , apoyando en las estrategias y toma de decisión para los proyectos de
        nuestros clientes.
        <br />
        <br />
        Nos caracterizamos en: <br />
        <b className="contrast">Dar atención personalizada</b> con{" "}
        <b className="contrast">entregas en el menor tiempo posible</b>.
      </>
    ),
    en: (
      <>
        is a company that has more than has more than
        <b className="contrast"> 19 years of experience </b>
        in{" "}
        <b className="contrast">
          {" "}
          characterizing the underground using geophysical methods
        </b>
        , supporting strategies and decision-making for our client's projects.
        <br />
        <br />
        We characterize ourselves in: <br />
        <b className="contrast">Give personalized attention</b> with{" "}
        <b className="contrast"> deliveries in the shortest time possible</b>.
      </>
    ),
  },
}

export const LABELS_LANDING_SERVICES = {
  SOIL_ENGINEERING: {
    title: {
      es: "Ingeniería de suelos",
      en: "Soil Engineering",
    },
    text: {
      es: `En esta área los métodos geofísicos son un apoyo importante en la caracterización del subsuelo en sitios destinados a la construcción de obras civiles; carreteras, puentes, presas, desarrollos habitacionales y gasoductos.

      Aplicaciones:

      Estratigrafía del sitio.
      Estimación de parámetros elástcios de los suelos.
      Identificacion de fracturas, cavernas y estratos saturados.
      Análisis sismotectónico del sitio.`,
      en: `In this area, geophysical methods are an important support int the characterization of the underground in sites intended for the construction of civil works; roads, bridges, dams, housing development and gas pipelines.

      Applications:

      Site stratigraphy.
      Estimation of elastic parameters of soils.
      Identification of fractures, caverns and saturated strata.
      Seismotectonic analysis of the site. `,
    },
  },

  EXPLORATION: {
    title: {
      es: "Exploración",
      en: "Exploration",
    },

    text: {
      es: `En esta área ofrecemos la aplicación de métodos geofísicos en la búsqueda, cuantificación de recursos naturales y para la conservacion del patrimonio arqueológico.

      Aplicaciones:

      Minería
      Geohidrología
      Arqueología`,
      en: `In this area, we offer the application of geophysical methods in the search, quantification of natural resources and for the conservation of archaeological heritage.`,
    },
  },

  ENVIRONMENTAL_ENGINEERING: {
    title: {
      es: "Ingeniería Ambiental",
      en: "Environmental Engineering",
    },

    text: {
      es: `El constante desarrollo tecnológico y el crecimiento demográfico están generando que los cuerpos de agua, aire y suelo sean mas susceptible a los agentes contaminantes.
        GCG Geofísica, contribuye en la mitigación y prevención de la contaminación para crear un ambiente más limpio y sustentable.

        Aplicaciones:

        Evaluación de suelos contaminados por hidrocarburos.
        seleccion y evaluación de sitios: para la construcción de rellenos sanitaros, plantas industriales y conjuntos habitacionales.
        Vulnerabilidad de acuíferos a la contaminación.
        Análisis de paisajes y aptitud del suelo.`,

      en: `Constant technological development and population growth are making bodies of water, air and soil more susceptible to pollutants.
        GCG Geophysics, contributes in the mitigation and prevention of contamination to create a cleaner and more sustainable environment.

        Applications:

        Evaluation of soils contaminated by hydrocarbons.
        selection and evaluation of sites: for the construction of sanitary landfills, industrial plants and housing complexes.
        Vulnerability of aquifers to contamination.
        Landscape analysis and soil suitability.`,
    },
  },

  RISK_MANAGEMENT: {
    title: {
      es: "Gestión de riesgos",
      en: "Risk management",
    },

    text: {
      es: `En los ultimos años el impacto de los fenómenos naturales, han provocado grandes pérdidas materiales y de vidas humanas a la sociedad. Por tal motivo, se necesita un instrumento de planeación donde se identifiquen y cuantifiquen las amenazas y riesgos.

          Aplicaciones:

          Atlas de riesgo.
          Ordenamientos del territorio
          Planificaciones Urbanas`,
      en: `In recent years, the impact of natural phenomena have caused great material and human loss to society. For this reason, a planning instrument is needed where threats and risks are identified and quantified.

          Applications:

          Risk Atlas.
          Land regulations
          Urban planning`,
    },
  },

  CONSULTING_TRAINING: {
    title: {
      es: "Consultoría y capacitación",
      en: "Consulting and training",
    },

    text: {
      es: `Es un servicio que se pone a su disposición con especilistas altamente capacitados para cubrir necesidades de nuestros clientes y asi contribuir a explorar las oportunidades de los mismos.
            El apoyo al cliente es personalizado y puede ser por:
            Dictamen técnico sobre estudios realizados por terceros, toma de decisiones, entre otros.
            Buscando siempre estar a la vanguardia en las herramientas y metodologías de la exploración del subsuelo ofrecemos este servicio para personas, empresas u organizaciones en la implementación de nuevas técnicas y/o en el análisis de resultados o procesos relacionados con la geofísica.  `,
      en: `It is a service that is made available to you with highly trained specialists to meet the needs of our clients and thus contribute to exploring their opportunities.
            Customer support is personalized and can be by:

            Technical opinion on studies carried out by third parties, decision making, among others.

            Always seeking to be at the forefront of the tools and methodologies of underground exploration, we offer this service to individuals, companies or organizations in the implementation of new techniques and / or in the analysis of results or processes related to geophysics. `,
    },
  },

  EQUIPMENT_RENTAL: {
    title: {
      es: "Renta de Equipo",
      en: "Equipment Rental",
    },

    text: {
      es: `Nuestra tecnología de punta también está presente en nuestros equipos de renta y a demás se contará con un operador para una óptima, cómoda y segura utilización.`,
      en: `Our state-of-the-art technology is also present in our rental equipment and others will have an operator for optimal, comfortable and safe use.`,
    },
  },

  LABEL_SERVICES: {
    es: "Servicios",
    en: "Services",
  },
  LABEL_SOIL_ENGINERING: {
    es: "Ingeniería de suelos",
    en: "Soil Engineering",
  },
  SUB_SOIL: {
    es: `Geofísica
         Geotecnia
        Mecánica de suelos`,
    en: `Geophysics
              Geotechnics
              Soil mechanics`,
  },
  LABEL_EXPLORATION: {
    es: "Exploración",
    en: "Exploration",
  },

  SUB_EXPLORATION: {
    es: `Minera
              Geohidrológica
              Arqueológica`,
    en: `Mining
              Geohydrological
              Archaeological`,
  },

  LABEL_ENVIROMENTAL: {
    es: "Ingeniería Ambiental",
    en: "Environmental Engineering",
  },
  SUB_ENVIROMENTAL: {
    es: `Contaminación
              Análisis del Paisaje`,
    en: `Pollution
              Landscape Analysis
`,
  },
  LABEL_RISKS: {
    es: "Gestión de riesgos",
    en: "Management of Natural Hazards",
  },
  SUB_RISKS: {
    es: `Atlas de riesgos
              Ordenamientos del territorio
              Planificación urbana`,
    en: `Natural Hazards and Risks`,
  },
  LABEL_CONSULTING: {
    es: "Consultoría y capacitación",
    en: "Consulting and training",
  },
  SUB_RENTAL: {
    es: `Geoeléctricos
              Sísmicos
              Electromagnéticos
              Magnéticos`,
    en: `Geoelectric
              Seismic
              Electromagnetic
              Magnetic`,
  },
  LABEL_RENTAL: {
    es: "Venta de equipo",
    en: "Sale of equipment",
  },
}

export const LABELS_LANDING_METHOD = {
  LABEL_METHOD: {
    es: "Métodos",
    en: "Methods",
  },
  LABEL_METHOD_TEXT: {
    es:
      "Son un conjunto de técnicas físicas y matemáticas, aplicadas a la caracterización del subsuelo e identificación de estructuras geológicas asociadas a la dinámica terrestre (vulcanismo y sismicidad).",
    en:
      "They are a set of physical and mathematical techniques, applied to the characterization of underground and identification of geological structures associated with earth dynamics (volcanism and seismicity)",
  },
  LABEL_BUTTON_METHOD: {
    es: "Mas Información",
    en: "More info",
  },

  MAGNETIC: {
    title: {
      es: "Magnéticos",
      en: "Magnetic",
    },
    text: {
      es: `Se basan en la aplicación de las leyes del campo magnético para localizar y definir las características de los cuerpos sepultados en el subsuelo. Son muy útiles para buscar minerales con altas susceptibilidades magnéticas.`,
      en: `They are based on the application of the laws of the magnetic field to locate and define the characteristics of bodies buried underground. They are very useful to look for minerals with high magnetic susceptibilities`,
    },
  },
  GEOELECTRIC: {
    title: {
      es: "Geoeléctrico",
      en: "Geoelectric",
    },
    text: {
      es: `Son un conjunto de técnicas que se basan en la aplicación de las   leyes del campo eléctrico natural e inducido. Los más conocidos son
          Sondeos Eléctricos Verticales (SEV)
          Tomografía Eléctrica Resistiva (TRE)
          Potencial Natural (SP)
          Potencial Inducido (PI)
          Métodos de Gradiente (GEM), entre otros.`,
      en: `They are a set of techniques that are based on the application of the laws of the natural and induced electric field. The best known are:
          Vertical Electric Sounding (VES)
          Electric Resistive Tomography (ERT)
          Self Potential (SP)
          Induced Potential (IP)
          Gradient Methods (GEM), among others.`,
    },
  },
  ELECTROMAGNETIC: {
    title: {
      es: "Electromagnéticos",
      en: "Electromagnetic",
    },
    text: {
      es: `Basados en la teoría electromagnética y su comportamiento está regido por las leyes de Maxwell, los más conocidos son:
          · Georadar
          · TEMS
          · CSAM`,
      en: `Based on electromagnetic theory and its behavior is governed by Maxwell's laws, the best known are:
          Georadar
          Transient Electromagnetic Sounding (TEMS)
          Controlled Source Audio Magnetoteluric (CSAM)`,
    },
  },
  SEISMIC: {
    title: {
      es: "Sísmicos",
      en: "Seismic",
    },
    text: {
      es: `Se fundamentan en las leyes que rigen el comportamiento de las ondas sísmicas en medios elásticos. Se clasifican en:
          · Reflexión Sísmica de Alta Resolución
          · Refracción Sísmica
          · Sísmica Pasiva (Microtremores) `,
      en: `They are based on the laws that govern the behavior of seismic waves in elastic media. They are classified into:
          · High Resolution Seismic Reflection
          · Seismic Refraction
          · Passive Seismic (Microtremores)`,
    },
  },
  RADIOMETRIC: {
    title: {
      es: "Radiométricos",
      en: "Radiometric",
    },
    text: {
      es: `Métodos que se basan en las leyes que rigen la desintegración y   comportamiento de los elementos radiactivos.`,
      en: `Methods that are based on the laws that govern the decay and behavior of radioactive elements.`,
    },
  },
  GEOCHEMISTS: {
    title: {
      es: "Geoquímicos",
      en: "Geochemists",
    },
    text: {
      es: `Se aplican para determinar el contenido de elementos químicos que componen las rocas.
          Estos métodos son importantes en:
          · Exploración minera
          · Ambiental
          · Calidad de agua y más`,
      en: `They are applied to determine the content of chemical elements that make up the rocks.
          These methods are important in:
          · Mining exploration
          · Environmental
          · Water quality and more`,
    },
  },
  REMOTE: {
    title: {
      es: "Percepción Remota",
      en: "Remote Perception",
    },
    text: {
      es: `Métodos basados en la reflexión y absorción de la luz pueden ser pasivos y activos, más conocidos como:
        Interpretación de imágenes satelitales tipo LANDSAT, SENTINEL, entre otros.`,
      en: `Methods based on light reflection and absorption can be passive and active, better known as:
      Interpretation of satellite images type LANDSAT, SENTINEL, among others.`,
    },
  },
}

export const LABELS_LANDING_CONTACT = {
  LABEL_CONTACT: {
    es: "Contacto",
    en: "Contact",
  },
  LABEL_EXPLORE: {
    es: "Explora tus Oportunidades",
    en: "Explore your Oportunities",
  },
  LABEL_EXPERT: {
    es: "Habla con un experto:",
    en: "Talk to the experts:",
  },
  LABEL_TEAM: {
    es: "Únete al equipo",
    en: "Join the team",
  },
  LABEL_PUEBLA: {
    es: "Puebla, Pue.",
    en: "Puebla, Mex.",
  },
}

export const LABELS_FORM_CONTACT = {
  FORM_TITLE: {
    message: {
      es: 'Para ponerte en contacto da click en "Siguiente"',
      en: 'To get in touch, clic "Next"',
    },
    small: {
      es: `Puedes pasar a la siguiente pregunta presionando tab`,
      en: `You can go to the next question by pressing tab`,
    },
  },
  LABEL_BACK: {
    es: "Anterior",
    en: "Back",
  },
  LABEL_NEXT: {
    es: "Siguiente",
    en: "Next",
  },
}

export const LABELS_LANDING_FOOTER = {
  SOCIAL_NET: {
    es: "Redes sociales",
    en: "Social Networks",
  },
  US: {
    es: "Nosotros",
    en: "US",
  },
  SERVICES: {
    es: "Servicios",
    en: "Services",
  },
  METHODS: {
    es: "Métodos",
    en: "Methods",
  },
  ALIANCES: {
    es: "Alianzas",
    en: "Aliances",
  },
  GALLERY: {
    es: "Galería",
    en: "Gallery",
  },
  CONTACT: {
    es: "Contacto",
    en: "Contact",
  },
  ARTISANO: {
    es: "Hecho artesanalmente a",
    en: "Handcrafted by",
  },
}

export const LABELS_LANDING_WEARE = {
  MISSION: {
    title: {
      es: "Misión",
      en: "Mission",
    },
    text: {
      es: `Somos una empresa que caracteriza el subsuelo mediante métodos geofísicos que generan información de valor a nuestros clientes, para determinar la viabilidad de sus proyectos.`,
      en: `We are a company that characterizes the underground with geophysical methods that generate valuable information to our clients, to determine the viability of their projects.`,
    },
  },
  VISION: {
    title: {
      es: "Visión",
      en: "Vision",
    },
    text: {
      es: `Ser la empresa líder en México de exploración del subsuelo por métodos geofísicos, creando especialistas de las geociencias, para satisfacer las necesidades de nuestros clientes generando un beneficio mutuo.`,
      en: `Be the leading company in Mexico of exploration of the subsoil by geophysical methods, to create specialists in geosciences, to satisfy the needs of our clients, generating mutual benefit.`,
    },
  },
  PHYLOSOPHY: {
    title: {
      es: "Filosofía",
      en: "Philosophy",
    },
    text: {
      es: `Generar la prevención de riesgos por fenómenos naturales, fomentando comunidades resilientes.`,
      en: `Generate the prevention of risks due to natural phenomena, fostering resilient communities.`,
    },
  },

  US: {
    es: "Nosotros",
    en: "Us",
  },
}

export const LABELS_LANDING_ALLIANCE = {
  ALLIANCE: {
    es: "Alianzas",
    en: "Alliances",
  },
}

export const LABELS_US_MEDIA = {
  MEDIA: {
    es: "Medios",
    en: "Media",
  },
}

export const LABELS_CLIENTS = {
  INTRO: {
    es:
      "Con más de 60 proyectos realizados a nivel nacional,algunos de nuestros clientes son:",
    en:
      "With more than 60 projects carried out nationwide, some of our clients are: ",
  },
  CLIENTS: {
    es: "Clientes",
    en: "Clients",
  },
}

export const LABELS_LANDING_GALLERY = {
  TITLE: {
    es: "Galería",
    en: "Gallery",
  },
  RETO_PUEBLA: {
    es:
      "Ganadores del 1er lugar en la categoría Innovación Empresarial MiPyME, esta iniciativa implementada por la SECOTRADE en el 2018, buscaba impulsar por medio de un programa de capacitación, mentoría y acompañamiento de expertos durante 8 semanas, a emprendedores y MiPyMES con ideas innovadoras y escalables.",
    en:
      "Winners of 1st place in the MiPyMe Business Innovation category, this initiative implemented by SECOTRADE in 2018, sought to promote, through a training program, mentoring and accompaniment of experts for 8 weeks, entrepreneurs and MiPyMes with innovative and scalable ideas.",
  },
  CEMUVA: {
    es:
      "Reafirmando nuestro compromiso con la sociedad el 8 de Marzo de 2019 junto con otros compañeros del Sector Verde Canacintra Puebla, realizamos una alianza con el municipio de Puebla para la cooperación en el saneamiento del Río Atoyac.",
    en:
      "Reaffirming our commitment to society on March 8, 2019 together with other colleagues from the Canacintra Puebla Green Sector, we made an alliance with the municipality of Puebla for cooperation in the sanitation of the Atoyac River.",
  },
  CANACINTRA: {
    es:
      "A partir del 2018 nos afiliamos a la Cámara de la Industria de la Transformación en el Sector Verde en el Edo de Puebla, de esta forma  y junto a otros empresarios poblanos estamos tratando de construir sociedades sostenibles.",
    en:
      "Since 2018, we joined the Chamber of the Industry of Transformation in the Green Sector in the Edo of Puebla, in this way and together with other businessmen from Puebla we are trying to build sustainable societies.",
  },
  ITEM_A: {
    caption: {
      es: `Estudio para identificación de zonas de hundimientos.
        Atlixco, Puebla. `,
      en: `Study to identify sinking areas.
        Atlixco, Puebla`,
    },
  },
  ITEM_B: {
    caption: {
      es: `Estudio para la identificación de zonas de hundimiento.
        Atlixco, Puebla.`,
      en: `Study to identify sinking areas.
        Atlixco, Puebla`,
    },
  },
  ITEM_C: {
    caption: {
      es: `Conferencia en la reunión del Centro Municipal Vive Atoyac (CEMUVA)
        Instalaciones del CEMUVA, Ciudad de Puebla. `,
      en: `Conference at the Vive Atoyac Municipal Center meeting(CEMUVA)
        CEMUVA facilities, Puebla`,
    },
  },
  ITEM_D: {
    caption: {
      es: `Conferencia en la reunión del Centro Municipal Vive Atoyac (CEMUVA)
        Instalaciones del CEMUVA, Ciudad de Puebla. `,
      en: `Conference at the Vive Atoyac Municipal Center meeting(CEMUVA)
        CEMUVA facilities, Puebla`,
    },
  },
  ITEM_E: {
    caption: {
      es: `Estudio de identificación estratigráfica para reforzamiento de cimentación
        Ciudad de Puebla.`,
      en: `Stratigraphic identification study for foundation reinforcement.`,
    },
  },
  ITEM_F: {
    caption: {
      es: `Desarrollo de programas de capacitación del personal de campo
        Oficinas GCG Geofísica.`,
      en: "Development of training programs for field personnel.",
    },
  },
  ITEM_G: {
    caption: {
      es: `Mediciones geoeléctricas y magnéticas.
        Municipio de Cazones de Herrera, Ver.`,
      en: `Magnetic and geoelectric measurements.
        Municipio de Cazones de Herrera, Ver.`,
    },
  },
  ITEM_H: {
    caption: {
      es: `Mediciones geoeléctricas y magnéticas.
        Municipio de Cazones de Herrera, Ver.`,
      en: `Geoelectric and magnetic measurements.
        Municipio de Cazones de Herrera, Ver.`,
    },
  },
  ITEM_I: {
    caption: {
      es: `Mediciones geoeléctricas y magnéticas.
        Municipio de Cazones de Herrera, Ver.`,
      en: `Geoelectric and magnetic measurements.
        Municipio de Cazones de Herrera, Ver.`,
    },
  },
  ITEM_J: {
    caption: {
      es: `Levantamientos de datos geológicos e hidrológicos.
        Proyecto Geofísico-Hidrogeológico en el municipio de Amozoc`,
      en: `Geological and hydrological data surveys.
        Geophysical-hydrogeological project in the municipality of Amozoc.`,
    },
  },
  ITEM_K: {
    caption: {
      es: `Levantamiento geológico para la identificación de cavernas.
        Proyecto Parque, municipio de Atlixco. `,
      en: `Geological survey for the identification of caves.
    Parque Project, Atlixco municipality. `,
    },
  },
  ITEM_L: {
    caption: {
      es: ` Levantamiento geológico – geofísico para la identificación de vetas de Barita.
        Municipio de Chiautla de Tapia, Puebla.`,
      en: `Exploration of barita.
        Chiautla de Tapia, Puebla.`,
    },
  },
  ITEM_M: {
    caption: {
      es: `Estudios magnéticos para la identificación de estructuras arqueológicas`,
      en: `Magnetic studies for the identification of archaeological structures`,
    },
  },
  ITEM_N: {
    caption: {
      es: `Planeación de los estudios geofísicos.
        Oficinas de GCG Geofísica, Cd. Puebla.`,
      en: `Planning of geophysical studies.
        Offices of GCG Geofísica, Cd. Puebla.`,
    },
  },
  ITEM_O: {
    caption: {
      es: `Estudio geofísico para determinar la distribución de fracturas en el subsuelo
        Cd. Puebla.`,
      en: `Geophysical study to determine the distribution of fractures in the subsoil
        Cd. Puebla.`,
    },
  },
  ITEM_P: {
    caption: {
      es: `Identificación de zonas susceptibles a deslizamientos.
        Sierra Norte del estado de Puebla.`,
      en: `Identification of susceptible landslide areas.
        Sierra Norte, Puebla.`,
    },
  },
  ITEM_Q: {
    caption: {
      es: `Aplicación de sísmica de refracción para determinar propiedades dinámicas del suelo
        Hidalgo, Mx.`,
      en: `Refraction seismic application to determine dynamic soil properties
        Hidalgo, Mx.`,
    },
  },
  ITEM_R: {
    caption: {
      es: `Evaluación de daños por deslizamiento.
        Sierra Norte de Puebla.`,
      en: `Slip damage assessment.
        Sierra Norte de Puebla.`,
    },
  },
  ITEM_S: {
    caption: {
      es: `Estudio geofísico hidrogeológico en la región de Sian Khan.
        Municipio de Tulum, Qro.`,
      en: `Hydrogeological geophysical study in the Sian Khan region.
        Tulum Municipality, Qro.`,
    },
  },
  ITEM_T: {
    caption: {
      es: `Consultoría sobre el método de  refracción sísmica.
        Puebla, Pue.`,
      en: `Consulting on the seismic refraction method.
        Puebla, Pue.`,
    },
  },
  ITEM_U: {
    caption: {
      es: `Estructuras dañadas a recintos históricos.
        Edo. Puebla.`,
      en: `Damaged structures in historical enclosures.
        Edo. Puebla.`,
    },
  },
  ITEM_V: {
    caption: {
      es: `Estudio de caracterización geofísica de suelo contaminado por hidrocarburos.
        Municipio de Potrero del Llano, Veracruz.`,
      en: `Study of geophysical characterization of soil contaminated by hydrocarbons.
        Municipality of Potrero del Llano, Veracruz.`,
    },
  },
  ITEM_W: {
    caption: {
      es: `Trabajo de oficina sobre modelado 3D.
        Proyecto hidrológico Puebla, Pue.`,
      en: `Office work on 3D modeling.
        Hydrological project. Puebla, Pue.`,
    },
  },
  ITEM_X: {
    caption: {
      es: `Perfil electroestratigráfico para entrega de resultados.`,
      en: `Electrostratigraphic profile to deliver results.`,
    },
  },
  ITEM_Y: {
    caption: {
      es: `Estudio de identificación estratigráfica para evaluación y reforzamiento de cimentación.`,
      en: `Stratigraphic identification study for evaluation and reinforcement of foundations.`,
    },
  },
  ITEM_Z: {
    caption: {
      es: `Distribución de zonas de interés minero.
        Chiapas, Mx. `,
      en: `Distribution of areas of mining interest.
        Chiapas, Mx.`,
    },
  },
  ITEM_AA: {
    caption: {
      es: `Mapa de anomalías magnéticas para agua subterránea.`,
      en: `Magnetic anomaly map for groundwater.`,
    },
  },
  ITEM_AB: {
    caption: {
      es: `Capacitación de técnicas geofísicas a nuestros clientes.`,
      en: `Training of geophysical techniques to our clients.`,
    },
  },
  ITEM_AC: {
    caption: {
      es: `Mediciones de rumbo y buzamiento`,
      en: `Heading and dip measurements`,
    },
  },
  ITEM_AD: {
    caption: {
      es: `Medición de resistividades eléctricas en campo.`,
      en: `Measurement of electrical resistivities in the field.`,
    },
  },
}

export const LABELS_US_TIME_LINE = {
  YEAR_1994: {
    es:
      "Estudios con modelos matemáticos para la propagación de contaminantes en el subsuelo y exploración para yacimientos de carbón.",
    en: "Underground and exploration for coal deposits.",
  },
  YEAR_1998: {
    es: "Trabajamos con el nombre Geofísica Ambiental.",
    en: "We work under the name Geofísica Ambiental.",
  },
  YEAR_2000: {
    es: "Nace oficialmente Peligros y Riesgos geológicos",
    en: "Geological Hazards and Dangers was officially born.",
  },
  YEAR_2002: {
    es:
      "Nuestros servicios eran Atlas de peligros y riesgos geológicos, geofísica para suelos contaminados, evaluación de sitios para la construcción de obra civil.",
    en:
      "Our services were Atlas of natural hazards and risks, geophysics for contaminated soils, evaluation of sites for the construction of civil works.",
  },
  YEAR_2005: {
    es:
      "Se anexan los servicios de exploración minera y ordenamiento del territorio. ",
    en: "The services of mining exploration and natural hazards are annexed.",
  },
  YEAR_2012: {
    es: "Se anexa el servicio de renta de equipo geofísico.",
    en: "The service of rent geophysical equipment is attached.",
  },
  YEAR_2017: {
    es:
      "Con más de 60 proyectos en nuestra trayectoria, renovamos nombre a GCG Geofísica e incorporamos consultoría, capacitación y venta de equipo",
    en:
      "With more than 60 projects in our history, we have renamed GCG Geophysics and incorporated consulting, training and equipment sales.",
  },
  YEAR_2018: {
    es:
      "Ganadores del 1er. Lugar de Reto Puebla en la categoría Innovación Empresarial MiPyME y nos hacemos miembros del sector verde de CANACINTRA Pue.",
    en:
      "Winners of the 1st. Challenge Place Puebla in the category MyPyME Business Innovation and we become members of the green economy sector of CANACINTRA Pue.",
  },
  YEAR_2019: {
    es:
      "Refrendamos nuestro compromiso con la sociedad, firmando un convenio con el CEMUHI para acciones de saneamiento del Río Atoyac.",
    en:
      "We reaffirm our commitment to society, signing an agreement with CEMUHI for actions to clean up the Atoyac River in Puebla, Mex.",
  },
}
